import * as React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

const BudgetingHintsPage = () => (
    <Layout>
        <Seo title={'Dealing with difficult times'} lang={'en-gb'} />
        <div className="threeCol purple cfx">
            <aside id="leftCol">
                <div id="tNav">
                    <ul>
                        <li><a href="/support/extra-help-when-you-need-it" className={'selected'}>Extra help when you need it</a></li>
                        <li><a href="/support/homes-for-ukraine">Homes for Ukraine</a></li>
                    </ul>
                </div>
            </aside>
            <section id="rightCol">
                <div className="contCol">
                    <div className="contColInr">
                        <div className="bubble purple cfx">
                            <div className="inr">
                                <h1>Extra help when you need it</h1>
                            </div>
                            <div className="bot"></div>
                        </div>
                        <p className="leading"></p>
                        <p>Dealing with unexpected and difficult situations such as serious illness, unemployment,
                        relationship breakdowns, or the death of a loved one can be tough.</p>
                        <p>Managing finances during such times can become even more challenging. Our <a href="/contact-us">Customer
                        Support Team</a> is here to provide assistance. </p>
                        <strong>Bereavement:</strong><div><br/></div>
                        <p>We are sorry for your loss. We know this is a difficult time. When someone passes away, we will
                        do all we can to help you manage the finances.</p>
                        <p>To register a death, please send us:</p>
                        <ul>
                            <li>A copy of the account holder’s death certificate</li>
                            <li>The name and address of the executor or administrator</li>
                        </ul>
                        <p>You can send these documents to us by:</p>
                        <ul>
                            <li>Emailing them to <a href="mailto:customersupportteam@nemoloans.co.uk">customersupportteam@nemoloans.co.uk</a></li>
                            <li>Posting them to: Principality Buildings, PO Box 89, Queen Street, Cardiff, CF10 1U</li>
                        </ul>
                        <p>We will contact you to explain your next steps once we receive the paperwork from you. This is
                        because there are two different ways of jointly owning property: joint tenants or tenants in
                        common.</p>
                        <div><br/></div>
                        <strong>My communication needs:</strong><div><br/></div>
                        <strong>Where possible, we can send our messages in different formats like;</strong><div><br/></div>
                        <ul>
                            <li>braille</li>
                            <li>large print</li>
                            <li>audio</li>
                        </ul>
                        <p>Let us know your preferred method of contact</p>
                        <strong>We can talk to you at different times:</strong>
                        <p>Were possible, we can contact you at set times on a set day within our opening hours.</p>
                        <strong>We can speak slowly, loudly and clearly:</strong>
                        <p>We will speak slowly, loudly and clearly, and where possible, prevent the use of jargon and use
                        simpler language to explain complex concepts.</p>
                        <strong>We can get in touch in different ways:</strong>
                        <p>Where possible we can contact you by email, telephone or post.</p>
                        <strong>We can change speech to text:</strong>
                        <p>Text relay offers text-to-speech and speech-to-text translation services for customers</p>
                        <div><br/></div>
                        <strong>Help managing my account:</strong><div><br/></div>
                        <strong>We can talk to another person about your account:</strong>
                        <p>We can speak to someone else on your behalf. Ask about our third-party options.</p>
                        <strong>We have alternative methods for verification:</strong>
                        <p>If you’re unable to sign or produce a consistent signature, we have alternative methods to verify
                        your identify.</p>
                        <div><br/></div>
                        <strong>Support from other organisations </strong><div><br/></div>
                        <p>Worrying about your finances can leave you feeling isolated. But you’re not alone. There are a
                        number of organisations you can seek free and independent advice from.</p>
                        <p>If you need urgent help, please call the Samaritans on &nbsp;<span
                                className="telnum"><strong>116 123.</strong></span></p>
                        <ul>
                            <li><a href="https://www.gov.uk/options-for-paying-off-your-debts" target="_blank">Gov.uk</a>: options for dealing with your debts</li>
                            <li><a href="https://www.moneyhelper.org.uk/" target="_blank">Money Helper</a>: information on budgeting and managing your money</li>
                            <li><a href="https://www.mentalhealthandmoneyadvice.org/toolkit/" target="_blank">Mental Health and Money Advice</a>: information on money and mental health</li>
                            <li><a href="https://www.stepchange.org/how-we-help/debt-advice.aspx" target="_blank">Step Change</a>: free debt advice - &nbsp;<span className="telnum"><strong>0800 138 1111</strong></span></li>
                            <li><a href="https://www.citizensadvice.org.uk/wales/debt-and-money/" target="_blank">Citizens Advice (Wales)</a> &&nbsp;
                            <a href="https://www.citizensadvice.org.uk/debt-and-money/" target="_blank">Citizens Advice (England)</a>: for debt and money issues</li>
                        </ul>

                        
                    </div>
                </div>
                <div className="fbCol"></div>
            </section>
        </div>
    </Layout>
)

export default BudgetingHintsPage;